/**
 * Это только ПРИМЕР. Вся разработка в @/src/site/*
 */
import {
  CallbackFormTrigger,
  FeedbackForm,
  FeedbackFormTrigger,
  ReviewQuestionForm,
  ReviewQuestionTrigger
} from './components'
import { PlatformBase } from './platform'

/**
 * Многие библиотеки требуют полной
 * загрузки страницы. И это надо учитывать
 * при проектировании приложения и
 * определения зависимостей.
 *
 * Для монтирования компонентов на страницу
 * созданы помощники `@/utils/vue-helpers.ts
 *
 * Чтобы управлять уже созданными компонентами,
 * рекомендуется объединить их в модуль или класс.
 *
 * Функциональность можно определить
 * методами класса.
 *
 */

export class Platform extends PlatformBase {
  constructor() {
    super()

    /**
     * Регистрация глобальных компонентов
     *
     * Регистрируем компоненты, которые планируем
     * использовать в шаблонах других компонентов
     * или глобально.
     *
     */
    this.registerComponents([
      { name: 'feedback-form', component: FeedbackForm}
    ])

    /**
     * Прямое монтирование компонентов
     */
    this.mount()
  }

  /**
   * Монтирование всех приложений и
   * сохранение ссылок во внутреннем объекте
   *
   * Монтирование позволяет создать и зарегистрировать
   * любые сложные структуру ссылок.
   *
   * Хотя лучше избегать этого. Реально возникают потребности,
   * например несколько кнопок триггеров для открытие форм
   * в модальном окне.
   *
   * Для монтирования созданы спец. вспомогательные методы
   * в `PlatformBase`. При необходимости их можно дополнить
   * или изменить логику.
   *
   */
  mount(): void {
    /**
     * Пример инициализации форм и триггеров
     */
    this.collection['feedbackForm'] = this.mountComponent('#feedback-form', FeedbackForm)
    this.collection['feedbackTriggers'] = this.mountAsTrigger('.js-feedback-trigger', 'FeedbackFormTrigger', FeedbackFormTrigger)
    this.collection['callbackTriggers'] = this.mountAsTrigger('.js-callback-form-trigger', 'CallbackFormTrigger', CallbackFormTrigger)
    this.collection['reviewsTriggers'] = this.mountAsTrigger('.js-review-question-trigger', 'ReviewQuestionTrigger', ReviewQuestionTrigger)
    this.collection['questionForm'] = this.mountComponent('#js-review-question-form', ReviewQuestionForm)

    /**
     * добавляем другие элементы/компоненты и т.д.
     */
  }
}
