<template>
  <a @click.stop.prevent="openForm()" class="review-question-trigger button" :class="customClass">
    <slot name="title">
      <span class="button-title">{{title}}</span>
    </slot>

    <slot name="icon" v-if="isNotHiddenIcon">
      <span class="icon">
        <i class="fas fa-comment-dots"></i>
      </span>
    </slot>
  </a>
</template>
<script>
import Vue from 'vue'
import {ReviewQuestionForm} from "@/platform/components/reviews";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";

export default Vue.extend({
  mixins: [FormTriggerMixin],
  methods: {
    openForm() {
      this.$buefy.modal.open({
        parent: this,
        component: ReviewQuestionForm,
        customClass: 'form-in-modal',
        canCancel: ['x'],
        width: 760,
        props: {
          /**
           * опция используется для определения
           * что форма открыта в модальном окне,
           * это не стандартная опция она добавлена
           * специально в форму, и отслеживается
           * при закрытии уведомления об успешной
           * отправке
           */
          trigger: true
        }
      })
    }
  }
})
</script>
<style lang="sass">
a.review-question-trigger.button
  .button-title
    margin-right: .5rem
</style>
