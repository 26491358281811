export default {
  busy: false,
  modalActive: false,
  cart: {},
  compare: {},
  user: undefined,
  bookmarks: [

  ]
}
