export default {
  lock (state) {
    state.busy = true
  },
  unlock (state) {
    state.busy = false
  },
  modalOn (state) {
    state.modalActive = true
  },
  modalOff (state) {
    state.modalActive = false
  }
}
