/**
 * установка фонового изображения для элемента
 * из атрибута `data-background`.
 *
 * фоновое изображение (background-image)
 * лучше работает для адаптивных блоков
 *
 * конечно дополнительно необходимо
 * добавить css-стили для определения поведения.
 *
 * ```html
 *  <div
 *    class="has-background-image"
 *    data-background="/assets/images/bg.png">
 *    ...
 *  </div>
 * ```
 *
 * рекомендуемый подход
 *
 * <figure class="image has-background-image"
 *  itemscope itemtype="http://schema.org/ImageObject"
 *  data-type="/assets/images/image.jpg">
 *   <a itemprop="contentUrl" href="/assets/images/image.jpg"></a>
 *   <figcaption itemprop="caption description">
 *    Image
 *   </figcaption>
 * </figure>
 *
 */
export class BgImages {
  elements: NodeListOf<HTMLElement>
  selector: string
  imageAttr: string

  constructor(selector: string|undefined) {
    this.selector = selector || '.has-background-image'
    this.imageAttr = 'data-background'
    this.elements = document.querySelectorAll(this.selector)
    this.elements.forEach(el => this.parser(el))
  }

  parser(el: HTMLElement): void {
    const image = el.getAttribute(this.imageAttr)
    if (image && image !== '') {
      el.style.backgroundImage = `url(${image})`
    }
  }
}

export function start(): BgImages {
  return new BgImages('.has-background-image')
}

