import Vue from 'vue'
import { store } from '@/store'
import { mapGetters } from 'vuex'
// import MaskedInput from 'vue-text-mask'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)

/**
 * Специальный миксин для форм
 *
 * включает в себя основные функции
 * с параметрами для работы формы.
 *
 * - статусы состояния (методы, состояния, помощники)
 * - универсальный метод отправки на сервер
 *
 * Чтобы использовать необходимо строго соблюдать
 * структуру шаблона. Настройки формы и валидации (vee-validate)
 *
 * Примеры использования
 *
 * - FeedbackForm (обратная связь)
 * - ReviewQuestionForm (отзыв)
 *
 * Рекомендуется к использованию со всеми формами проекта.
 *
 * @todo rewrite to typescript
 * @todo see https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/mixins
 *
 */
const Status = {
  store,
  props: {
    /**
     }
     * если форма открывается в
     * модальном окне, через trigger
     * необходимо указать это в параметрах
     * модального окна
     *
     * При работе с триггером меняется
     * логика отображения статусов и логика
     * сброса состояния формы
     */
    trigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      /**
       * Возможность заполнения демо данными
       */
      demoShortcuts: false,
      /**
       * @todo реализовать расширенный вывод ошибок
       */
      showExtendedError: false,
      /**
       * локальные ошибки, используется
       * при получении ошибок с сервера
       *
       * @todo rename (конфликтует с VeeValidate Observer)
       */
      errors: [],
      /**
       * стандартные поля формы обратной связи
       * добавить и переопределить поля можно
       * в конкретном компоненте, где
       * используется миксин
       */
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
        file: null
      },
      /**
       * Данные для ручного тестирования формы.
       * Для авто заполнения по нажатию комбинации клавиш.
       * `CTRL+.` для `valid` и `CTRL+,` для `invalid`.
       */
      demo: {
        valid: {
          name: 'Demo Name',
          email: 'demo@diera.ru',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        },
        invalid: {
          name: 'Demo Name',
          email: 'demo@diera',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        }
      },
      /**
       * статус состояния компонента
       */
      status: {
        /**
         * начальное состояние
         */
        pristine: true,
        /**
         * успешно отправлено
         */
        success: false,
        /**
         * ошибки при отправке на сервер
         */
        error: false,
        /**
         * пользователь закрыл сообщение
         * об ошибках, после неудачной
         * отправки на сервер
         */
        dirty: false
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    hasErrors() {
      return this.errors.length > 0
    },
    isSuccess() {
      return this.status.success === true
    },
    isPristine() {
      return this.status.pristine === true
    },
    isNotSuccess() {
      return this.status.success !== true
    }
  },
  mounted () {
    if (this.demoShortcuts) {
      document.addEventListener('keyup', (e) => {
        if(String(e.code) === 'Period' && e.ctrlKey) {
          this.setDemoFormData('valid')
        }
        if(String(e.code) === 'Comma' && e.ctrlKey) {
          this.setDemoFormData('invalid')
        }
      })
    }
  },
  methods: {
    /**
     * Помощник для заполнения формы
     * демо-данными
     */
    setDemoFormData (key) {
      Object.keys(this.demo[key]).forEach((x) => {
        this.formData[x] = this.demo[key][x]
      })
    },
    /**
     * Битрикс BX
     *
     * Используется концепция контроллеров/действий
     * доступно в Битрикс начиная с 18.x.
     *
     * Контроллеры создаются в главном модуле `platform.main`.
     * Компонент `platform:api` больше не используется.
     *
     */
    submit() {
      if (!window.BX) {
        console.error('Required BX')
      }

      /**
       * перед отправкой необходимо
       * очистить ошибки и состояния
       */
      this.status.dirty = true
      this.status.success = false
      this.status.error = false
      this.status.pristine = false
      this.errors.splice(0, this.errors.length + 1)

      const form = new FormData(this.$refs.form)
      this.$store.commit('lock')


      /**
       * Вся работа должна проходить через Контроллеры
       *
       * @see https://dev.1c-bitrix.ru/api_help/js_lib/ajax/bx_ajax_runaction.php
       * @see bitrix/modules/main/install/js/main/core/core_ajax.js
       */
      window.BX.ajax.runAction('platform:main.api.form.submit', {
        //headers: {},
        //method: 'post',
        //dataType: 'json',
        //timeout: 0,
        //url: '',
        //preparePost: '',
        data: form
      })
        .then(() => {
          this.$store.commit('unlock')
          this.status.success = true
          this.resetForm()
        })
        .catch(({ errors }) => {
          /**
           * @todo parse errors
           */
          this.$store.commit('unlock')
          this.errors.push(errors)
          this.status.error = true
        })
    },

    /**
     * отправка запроса на сервер
     * @deprecated использовать BX
     */
    submitAxios() {
      /**
       * перед отправкой необходимо
       * очистить ошибки и состояния
       */
      this.status.dirty = true
      this.status.success = false
      this.status.error = false
      this.status.pristine = false
      this.errors.splice(0, this.errors.length + 1)

      /**
       * предварительная принудительная
       * проверка данных формы
       */
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }

        const form = new FormData(this.$refs.form)

        this.$store.commit('lock')

        /**
         * собственно отправка запроса на сервер
         * необходимо убедиться что идентификатор формы
         * указан верно
         */
        this.$http.post(`/platform/api/forms/${this.formId}/submit`, form, {})
          .then(() => {
            this.$store.commit('unlock')
            this.status.success = true
            this.resetForm()
          })
          .catch(({body}) => {
            /**
             * @todo парсер ошибок
             */
            this.$store.commit('unlock')
            this.errors.push(body.errors)
            this.status.error = true
          })
      })
    },
    /**
     * полный сброс
     * состояния компонента и формы
     */
    resetState() {
      this.resetStatus()
      this.resetForm()
    },
    /**
     * полный сброс всех заполненных полей
     * и состояния проверки данных (валидации)
     *
     * Можно использовать кнопку reset,
     * VeeValidate обрабатывает ее нажатие
     * по-умолчанию.
     *
     * Данные сброс применяется программно
     * при успешной отправке формы
     *
     */
    resetForm() {
      /**
       * reset all keys
       */
      for (const field in this.formData) {
        if (Object.prototype.hasOwnProperty.call(this.formData, field)) {
          this.formData[field] = null
        }
      }

      /**
       * необходимо добавить ref="observer" к
       * корневому validation-observer
       */
      this.$nextTick(() => {
        this.$refs.observer.reset();
      })
    },
    /**
     * сброс локального статуса компонента
     * на начальное состояние
     */
    resetStatus() {
      this.status.success = false
      this.status.error = false
      this.status.dirty = false
      this.status.pristine = true
      // clean errors
      // this.errors.splice(0, this.errors.length + 1)
    },
    /**
     * работает только с компонентами Buefy
     * или им подобными
     */
    onCloseNotification() {
      /**
       * при открытии формы внутри
       * модального окна закрытие при
       * статусе 'success' должно
       * закрывать модальное окно
       *
       * для указания на работу
       * в модальном окне используется
       * параметр trigger
       *
       * логика закрытия и открытия, обмена
       * событиями между компонентами имеет
       * первостепенное значение.
       *
       * При неправильном применении легко получить
       * ошибки типа `CallStack Exceeded`
       */
      if (this.isSuccess) {
        /**
         * свойство trigger должно
         * передаваться/устанавливаться
         * при открытии/инициализации
         * модального окна (см. FeedbackFormTrigger)
         */
        if (this.trigger) {
          /**
           * событие закрытия модального окна
           * будет перехвачено в обработано (по-умолчанию)
           */
          this.$emit('close')
          this.$destroy()
        } else {
          this.resetStatus()
        }
      } else {
        this.status.pristine = false
        this.status.dirty = true
      }
    },
     /**
     * Склонение числовых значений
     *
     * num2str(1, ['ящик', 'ящика', 'ящиков'])
     * num2str(1, ['минута', 'минуты', 'минут'])
     */
      num2str(n, text) {
        n = Math.abs(n) % 100
        const n1 = n % 10

        if (n > 10 && n < 20) {
          return text[2]
        }

        if (n1 > 1 && n1 < 5) {
          return text[1]
        }

        if (n1 === 1) {
          return text[0]
        }

        return text[2]
      }
  }
}

export default Status
