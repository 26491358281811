<template>
  <a class="callback-form-trigger button" @click.stop.prevent="openForm()" :class="customClass">
    <slot name="title">
      <span class="button-title">{{title}}</span>
    </slot>

    <slot name="icon" v-if="isNotHiddenIcon">
      <span class="icon">
        <i class="fas fa-comment-dots"></i>
      </span>
    </slot>
  </a>
</template>
<script>
import Vue from 'vue'
import CallbackForm from "@/platform/components/callback/CallbackForm";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";

export default Vue.extend({
  mixins: [FormTriggerMixin],
  methods: {
    openForm() {
      this.$buefy.modal.open({
        component: CallbackForm,
        width: 640,
        customClass: 'form-in-modal',
        canCancel: ['x'],
        /**
         * опция используется для определения
         * что форма открыта в модальном окне,
         * это не стандартная опция она добавлена
         * специально в форму, и отслеживается
         * при закрытии уведомления об успешной
         * отправке
         */
        props: {
          trigger: true
        }
      })
    }
  }
})
</script>
