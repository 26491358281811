import {Swiper} from 'swiper'
import {SwiperMounter} from '@/utils/swiper-mounter'

/**
 * стили можно импортировать сразу
 * или использовать отдельный файл стилей
 * для возможности переопределения "родных"
 * стилей и переменных Swiper
 */

require('./style.scss')

export function start(): Swiper|undefined {
  return new SwiperMounter().start('.swiper-pswp-demo', {
    autoplay: {
      delay: 3000
    },
  })
}
