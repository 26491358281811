<template>
  <div class="reviews-question-form">
    <validation-observer ref="observer" v-slot="{invalid, handleSubmit}">

      <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title"> Отправить сообщение</p>
      </div>

      <div class="form-messages" ref="messages">
        <!--      // отображение сообщения основано на комбинации/изменении статусов-->
        <!--      // [также можно использовать b-message вместо b-notification]-->
        <b-notification type="is-danger" @close="onCloseNotification" has-icon v-model="status.error">
          <p><strong>Ошибка! Не удалось отправить сообщение.</strong></p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{item}}</strong>
            </span>
          </template>
        </b-notification>
        <b-notification type="is-success" @close="onCloseNotification" has-icon v-model="status.success">
          <p><strong>Спасибо! Ваше сообщение отправлено.</strong></p>
          <p>Постараемся рассмотреть ваше обращение как можно быстрее.</p>
        </b-notification>
      </div>

      <div class="form-wrapper" v-if="isNotSuccess" ref="wrapper">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <!--        // на разных сайтах для одних и тех же форм-->
          <!--        // идентификатор может быть разным, это связано с-->
          <!--        // правилами обновления и синхронизации данных-->
          <!--        // --- -->
          <!--        // обязательный атрибут, по этому атрибуту-->
          <!--        // битрикс понимает какая форма отправлена со страницы-->
          <input type="hidden" name="WEB_FORM_ID" :value="formId">
          <!--        // имена полей уникальны в одной установке Битрикс-->
          <!--        // Битрикс получает список полей по ID формы и затем-->
          <!--        // выбирает поля конкретной формы из объекта $_REQUEST-->
          <!--        // ФИО-->
          <validation-provider v-slot="v" rules="required" name="Имя и Фамилия" slim>
            <b-field message="ваши контакты, имя и фамилия" label="Ваши имя и фамилия *" :type="{ 'is-danger': v.invalid && v.dirty }">
              <b-input name="form_text_42"
                       placeholder="Имя и Фамилия"
                       v-model="formData.name">
              </b-input>
            </b-field>
            <p class="help is-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <validation-provider v-slot="v" rules="required|max:1500" name="Сообщение" slim>
            <b-field :message="[{'ваш отзыв, пожелание, вопрос или сообщение': v.pristine}]" label="Ваше сообщение *" :type="{'is-danger': v.invalid && v.dirty}">
              <b-input type="textarea"
                       name="form_textarea_43"
                       placeholder="напишите кратко ваш вопрос, пожелание или отзыв..."
                       v-model="formData.message">
              </b-input>
            </b-field>
            <p class="help has-text-danger">{{v.errors[0]}}</p>
          </validation-provider>

          <div class="columns">
            <div class="column">
              <validation-provider name="Email" rules="required|email" v-slot="v" slim>
                <b-field message="ваша электронная почта" label="Email *" :type="{ 'is-danger': v.invalid && v.dirty }">
                  <b-input v-model="formData.email" name="form_email_44"></b-input>
                </b-field>
                <p class="help has-text-danger">{{v.errors[0]}}</p>
              </validation-provider>
            </div>

            <div class="column">
              <validation-provider name="Телефон" v-slot="v" slim>
                <b-field message="ваш контактный телефон, включая код города и страны" label="Телефон" :type="{ 'is-danger': v.invalid && v.dirty }">
                  <b-input v-model="formData.phone" name="form_text_45" placeholder="+7 000 000-00-00"></b-input>
                </b-field>
                <p class="help has-text-danger">{{v.errors[0]}}</p>
              </validation-provider>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field class="file is-primary" :class="{'has-name': !!formData.file}">
                <b-upload class="file-label" v-model="formData.file" name="form_file_49">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">прикрепить файл</span>
                </span>
                  <span class="file-name" v-if="formData.file">{{ formData.file.name }}</span>
                </b-upload>
              </b-field>
              <p class="help">Можно приложить файл к отзыву, например скриншот или документ с пояснениями или подтверждением описываемой ситуации.</p>
            </div>

            <div class="column">
              <b-rate size="is-large" :spaced="true" v-model="formData.rate"></b-rate>
              <input type="hidden" v-if="formData.rate > 0" name="form_hidden_46" :value="formData.rate">
              <p>Добавьте оценку к отзыву</p>
              <p class="help">1 (плохо) - 5 (отлично)</p>
            </div>
          </div>

          <b-message style="margin-top: 2rem;">
            <p class="help">Отправляя сообщение вы соглашаетесь <a target="_blank" href="/agreement">с условиями</a>. Если вы не
              согласны <a target="_blank" href="/agreement">с условиями пользовательского соглашения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.</p>

            <div class="is-divider"></div>
            <p class="help">Ваш отзыв будет проверен модератором и уже после этого опубликован на сайте. Мы оставляем за собой право не публиковать ваш отзыв без объяснения причин. Чтобы отзыв или вопрос был опубликован, пожалуйста постарайтесь изложить ваши мысли кратко и понятно, будьте предельно корректны, проявите уважение к компании и другим пользователям сайта.</p>
          </b-message>

          <div class="level controls is-mobile">
            <div class="level-left">
              <div class="level-item">
                <b-button type="is-info" native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">
                  отправить
                </b-button>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-button native-type="reset">очистить форму</b-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
/**
 * миксин содержит все необходимые
 * функции и параметры для работы формы
 *
 * нестандартная, любая другая необходимая
 * функциональность добавляется в компонент
 * как обычно, стандартным способом
 *
 */
import FormCommonMixin from '@/mixins/form-common-mixin'

/**
 * форма добавления вопроса
 * сервиса "вопрос-ответ"
 *
 * Форма жестко привязана к
 * идентификаторам полей вопросов
 * при переносе на другой сайт,
 * изменении полей вопросов или
 * логики работы необходимо
 * внести изменения и пере собрать
 * библиотеку.
 *
 */
export default Vue.extend({
  mixins: [FormCommonMixin],
  /**
   * @todo создать API
   *
   * для отрисовки и работы формы
   * лучше всего создать специализированные
   * точки доступа (endpoints) в
   * стандартном API (/platform/api/*)
   *
   * - получать данные о форме
   * - данные, список результатов
   * - данные статусов и доп. полей
   * - запросы для смены статуса
   * - запросы для публикации ответа
   * - запросы для сохранения формы
   *
   */
  data(){
    return {
      /**
       * идентификатор формы
       * на стороне битрикс
       *
       * при миграции на другой проект
       * следует проверить ID, изменить
       * если требуется и пере собрать
       * приложение.
       *
       */
      formId: 4,
      /**
       * `formData` так же определена
       * в миксине. Все параметры объединяются
       * в объект. "Лишние" поля будут
       * просто проигнорированы.
       */
      formData: {
        name: '',
        email: '',
        phone: '',
        rate: null
      }
    }
  }
})
</script>
<style lang="sass" scoped>
@import "helpers"
.reviews-question-form
  background: #fff
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
// modal styles
.form-in-modal .reviews-question-form
  .form-wrapper
    background: #fff
    padding: 1rem
  .form-header
    background: $primary
    p.title
      padding-left: 1rem
      padding-top: 1rem
      color: $primary-invert
</style>
