<template>
  <div class="platform-feedback-form">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">
      <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title">Обратная связь</p>
      </div>

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>

        <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
          <p class="title is-5" style="margin-bottom: 0.5">
            Спасибо! Ваше сообщение отправлено.
          </p>
          <p>Постараемся ответить как можно быстрее.</p>
        </b-notification>
      </div>

      <div class="form-wrapper" v-if="isNotSuccess">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">

          <validation-provider v-slot="v" rules="required" name="Контакты" slim>
            <b-field label="Контакты *" message="Ваши контакты, Ф.И.О" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
              <b-input name="form_text_41" placeholder="Имя и Фамилия" v-model="formData.name"></b-input>
            </b-field>
            <p class="has-text-danger" v-if="v.invalid && v.dirty">
              {{v.errors[0]}}
            </p>
          </validation-provider>

          <div class="columns is-multiline">
            <div class="column">
              <validation-provider v-slot="v" :rules="{ required: true, regex: /^\d{3}\s\d{3}-\d{2}-\d{2}/ }" name="Телефон" slim>
                <b-field label="Телефон *" message="Ваш контактный телефон" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <p class="control">
                    <b-button class="is-dark">+7</b-button>
                  </p>
                  <b-input v-mask="'### ###-##-##'" native-type="tel" name="form_text_38" placeholder="000 000-00-00" v-model="formData.phone"></b-input>
                </b-field>
                <p class="has-text-danger" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>

              <!-- <b-input type="tel" v-mask="'+7 ### ###-##-##'"></b-input> -->

            </div>
            <div class="column">
              <validation-provider v-slot="v" rules="required|email" name="Email" slim>
                <b-field label="Email *" message="Ваш адрес электронной почты" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_email_2" placeholder="email@email.ru" v-model="formData.email"></b-input>
                </b-field>
                <p class="has-text-danger" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>
            </div>
          </div>

          <validation-provider v-slot="v" rules="required|max:1000" slim name="Сообщение">
            <b-field label="Сообщение *" message="Сообщение или ваши вопросы" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
              <b-input name="form_textarea_1" placeholder="сообщение или вопрос..." type="textarea" v-model="formData.message"></b-input>
            </b-field>
            <p class="has-text-danger" v-if="v.invalid && v.dirty">
              {{v.errors[0]}}
            </p>
          </validation-provider>

          <b-field class="file is-primary" :class="{'has-name': !!formData.file}">
            <b-upload class="file-label" v-model="formData.file" name="form_file_51">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">прикрепить файл</span>
            </span>
              <span class="file-name" v-if="formData.file">{{ formData.file.name }}</span>
            </b-upload>
          </b-field>

          <b-message style="margin-top: 2rem;">
            <p class="help">
              Отправляя сообщение вы соглашаетесь
              <a target="_blank" href="/agreement">с условиями</a>. Если вы не
              согласны <a target="_blank" href="/agreement">с условиями
              пользовательского соглашения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами,
              для ответа на ваше сообщение.
            </p>
          </b-message>

          <div class="controls">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <b-button native-type="reset">очистить форму</b-button>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-button type="is-primary" native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">отправить</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </validation-observer>

    <!--
    /**
      * vue-portal позволяет отрисовать виджет/компонент
      * в любом месте приложения или вне приложения на странице.
      *
      * Преимущества перед обычным монтированием, как изолированное
      * приложение, в том, что сохранятся возможность влиять на
      * поведение компонента.
      * <MountingPortal mountTo=".feedback-portal" multiple>
      *   <b-button @click.prevent="click">Обратная связь</b-button>
      * </MountingPortal>
      */
    -->
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  name: 'feedback-form',
  mixins: [FormCommonMixin],
  data() {
    return {
      /**
       * Без указания правильного идентификатора
       * данные не будут сохранены. И идентификатор формы и
       * названия полей должны строго соответствовать настройкам
       * сайта.
       */
      formId: 1,

      /**
       * Набор стандартных полей создается
       * в "миксине" src/mixins/form-common-mixins.js
       *
       * Чтобы переопределить или дополнить данные,
       * надо создать данные непосредственно здесь
       * в компоненте. Если создавать одинаковые ключи,
       * они будут переопределены, если новые ключи, то
       * данные будут объединены.
       *
       * Ключ с данными формы - `formData`,
       * в нем добавлены часто используемые в формах ключи
       *
       * - name
       * - email
       * - phone
       * - message
       * - file
       *
       * Например чтобы добавить поле Адрес:
       *
       * ```js
       * data() {
       *   ...
       *   formData: {
       *     address: ''
       *   }
       *   ...
       * }
       *
       * Все данные будут объединены вместе. Конечный объект
       * будет содержать все ключи
       *
       * ```js
       * {
       *    name: '',
       *    email: '',
       *    phone: '',
       *    message: '',
       *    file: '',
       *    address: ''
       * }
       * ```
       *
       * Важно понимать, что на сервер отправляются все поля
       * формы, но сохраняются только поля, которые имеют
       * идентификаторы битрикс.
       *
       */
      formData: {

      },

      /**
       * Возможность заполнять демо-данными
       * форму по нажатию комбинаций клавиш.
       */
      demoShortcuts: false,

      /**
       * демо данные для тестирования
       *
       * Для "ручного" тестирования формы, чтобы каждый раз
       * не набирать данные в полях формы. Можно использовать
       * демо данные. Необходимо создать все ключи/поля формы,
       * соответствующие `formData`.
       *
       */
      demo: {
        /**
         * набор для проверки - CTRL+.
         */
        valid: {
          name: 'Demo Name',
          email: 'demo@diera.ru',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        },
        /**
         * набор для проверки ошибок - CTRL+,
         */
        invalid: {
          name: 'Demo Name',
          email: 'demo@diera',
          phone: '+7 000 000-00-00',
          message: 'Demo message.'
        }
      }
    }
  },
  methods: {
    /**
     * при рекурсивных ссылках
     * больше проблем чем пользы
     *
     * - <MountingPortal /> вызывается каждый раз при открытии формы
     * - Надо задавать имя и рег. компонент глобально
     *
     * @todo это только пример (НЕ использовать)
     */
    // click() {
    //   this.$buefy.modal.open({
    //     component: 'feedback-form'
    //   })
    // }
  }
})
</script>

<style lang="sass" scoped>
@import 'helpers'
.platform-feedback-form
  // можно использовать переменные и миксины:
  //
  // color: $primary
  // color: $orange
  // +mobile
  //   background: $dark

  border: 1px solid #ddd
  padding: 1rem

  // добавлять и использовать свои миксины
  // (src/sass/_custom-variables.scss)
  +platform-bg-gradient(#ccc, #ccc)

  p.has-text-danger
    background: $danger
    color: #fff!important
    padding: .2rem
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px dotted #ddd
.field
  margin-bottom: 0
  margin-top: 0.5rem
.form-in-modal .platform-feedback-form
  padding: 0
  border: none!important
  .form-wrapper
    background: #fff
    padding: 0 2rem 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>
