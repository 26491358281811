import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper'

/**
 * начиная с версии 5.x различные модули необходимо
 * импортировать и подключать отдельно, это касается и
 * javascript и стилей, это рекомендованный подход
 */
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

/**
 * стили библиотеки подключать всегда в общий поток стилей
 * main.sass/libs, иначе они будут выделены
 * в отдельный "чанк" и не будут подключены
 * в стили шаблона.
 */

//require('./style.scss')

export function start(selector = '.swiper-products'): Swiper|undefined {
  if (!document.querySelector(selector)) return
  /**
   * @see https://swiperjs.com/api/
   */
 return new Swiper(selector, {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    loop: true,
    speed: 100,
    slidesPerView: 5,
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-pagination-product',
      clickable: true,
      dynamicBullets: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1216: {
        slidesPerView: 4
      },
    }
  })
}

