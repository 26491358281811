/**
 * Есть несколько вариантов создания "табов".
 *
 * 1) Вручную задать соответствие контента и таба, например через `rel`
 * 2) Использовать Buefy
 * 3) Использовать внешний скрипт
 *
 * Для анимации использовать `gsap`.
 *
 * Для манипуляции DOM рекомендуется использовать обычный js, без jQuery.
 *
 * Для Bulma активный таб, это элемент с классом `is-active`.
 *
 * Классически: пользователь выбирает "таб", скрипт должен
 * сбросить состояние выбора и установить заново выбранные "tab" и
 * "tab-content" в соответствие с индексом элемента.
 *
 * @see https://bulma.io/documentation/components/tabs/
 *
 */

require('./tabs.scss')
/**
 * Принимает название селектора для корневого
 * элемента "табов". На странице может быть несколько элементов.
 */
export function start(selector = '.js-tabs-price'): Array<BulmaTabs> {
  const rootNodes: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
  const tabs: BulmaTabs[] = []
  rootNodes.forEach((x) => {
    tabs.push(new BulmaTabs(x))
  })

  return tabs
}

/**
 * Для работы необходимо использовать правильную разметку
 *
 * <div class="js-tabs-auto">
 *   <div class="tabs">
 *      <ul>
 *         <li><a>Tab 1</a></li>
 *      </ul>
 *   </div>
 *   <div class="tabs-contents">
 *      <div class="tab-content">Tab content 1</div>
 *   </div>
 * </div>
 *
 */
export class BulmaTabs {
  root: HTMLElement|null
  links: NodeListOf<HTMLLinkElement>|undefined
  contents: NodeListOf<HTMLLinkElement>|undefined
  activeTab = 0

  constructor (root:HTMLElement) {
    this.root = root

    if (!this.root) {
      console.log('TABS: root element must be an HTML')
      return
    }

    this.links =  this.root?.querySelectorAll('.tabs ul li')
    this.contents =  this.root?.querySelectorAll('.tabs-contents .tab-content')

    const tabsCount: number = this.links?.length || 0
    const contentsCount: number = this.contents?.length || 0

    /**
     * Не надо убирать вывод консоли из класса, лучшим
     * решением будет изменить функцию start или написать
     * предварительную проверку.
     *
     * Вывод предупреждений гарантирует, что функции
     * используются как надо.
     */

    if (tabsCount === 0) {
      console.warn(`TABS: Не найдены табы в элементе ${root.className}.`)
      return
    }

    if (contentsCount === 0) {
      console.warn(`TABS: Не найдены элементы контента в элементе ${root.className}.`)
      return
    }

    if (tabsCount !== contentsCount) {
      console.warn(`TABS: Не совпадает количество табов и контента в элементе ${root.className}.`)
      return
    }

    /**
     * разметка контента, добавление дата-атрибутов и классов
     */
    this.contents?.forEach((x:HTMLElement, index: number) => {
      x.dataset.name = `tab-content-${index}`
      x.classList.add(`tab-content-${index}`)
      x.classList.remove(`is-active`)

      if (index === this.activeTab) {
        x.classList.add(`is-active`)
      }
    })

    /**
     * обходим все ссылки (табы)
     */
    this.links?.forEach((x:HTMLElement, index:number) => {
      /**
       * добавляем метки для целевых элементов
       */
      x.dataset.target = `tab-content-${index}`
      x.classList.remove('is-active')

      if (index === this.activeTab) {
        x.classList.add('is-active')
      }

      x.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault()

        if (e.currentTarget instanceof HTMLLIElement) {
          if (e.currentTarget.classList.contains('is-active')) {
            console.log('already active')
            return
          }

          /**
           * снимаем все активности
           */
          this.clearAllTabActiveClass()

          /**
           * устанавливаем активный таб и контент
           */
          this.setActive(e.currentTarget)
        }
      })
    })
  }

  clearAllTabActiveClass (): void {
    this.contents?.forEach(x => x.classList.remove('is-active'))
    this.links?.forEach(x => x.classList.remove('is-active'))
  }

  /**
   * Установка активного состояния
   * Поиск происходит относительно корневого элемента
   */
   setActive (node: HTMLLIElement): void {
    const targetClass: string|undefined = node.dataset.target

    if (targetClass) {
      this.root?.querySelector(`.${targetClass}`)?.classList.add('is-active')
    }

    node.classList.add('is-active')
  }
}
