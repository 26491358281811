import Vue from 'vue'

/**
 * самые часто используемые
 * компоненты Buefy
 * @see https://github.com/buefy/buefy/issues/1955
 */
// import { ConfigProgrammatic } from 'buefy'
import config from 'buefy/src/utils/config'
import {
  Button,
  Field,
  Icon,
  Input,
  Numberinput,
  Radio,
  Select,
  Slider,
  Tabs,
  Modal,
  Toast,
  Notification,
  Upload,
  Message,
  Rate
} from 'buefy/src/components'

/**
 * @see https://buefy.org/documentation/constructor-options/
 */
config.defaultIconPack = 'fas'

/**
 * Подключать только используемые на сайте
 * компоненты. Стили подключаются отдельно
 * в src/sass/_buefy.scss
 */
Vue.use(Rate)
Vue.use(Numberinput)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(Select)
Vue.use(Input)
Vue.use(Field)
Vue.use(Slider)
Vue.use(Radio)
Vue.use(Toast)
Vue.use(Modal)
Vue.use(Notification)
Vue.use(Button)
Vue.use(Message)
Vue.use(Upload)
