/**
 * Использование require гарантирует
 * правильную очередность загрузки
 *
 * Это общие библиотеки, он только загружают
 * и подключают общую функциональность и
 * для `PLatform` и для `Site`.
 */
require('@/custom/validate')
require('@/custom/http')
require('@/custom/buefy')
require('@/custom/portal')

/**
 * Начинайте с самого простого,
 * держите файл простым, не подключайте лишнего.
 *
 * Чем меньше функциональности, тем лучше.
 * Различные примеры в `main.ts.example`.
 *
 * Создавайте стили, шаблон проекта сначала. И после
 * завершения основных работ переходите к js.
 *
 * Используйте shuffle.dev по максимуму, прежде чем
 * перейти к работе с Битрикс.
 *
 * Переносите и используйте только необходимый
 * функционал.
 *
 * При разработке выходите из админки. Это уменьшит
 * количество загружаемых ресурсов.
 *
 * - platform - готовые решения и примеры кода
 * - runtime - исполняемые скрипты (примеры)
 * - site - функционал сайта
 *
 */

/**
 * компоненты и скрипты
 *
 * - main.sass - все стили
 * - platform - общие скрипты и примеры кода (es/vue)
 * - runtime - общие скрипты и утилиты
 * - site - ЗДЕСЬ вся функциональность, модули сайта
 *
 * @todo разобрать разницу import и require
 * @todo создать альтернативную структуру на import/export
 */
require('./template_styles.scss')
require('./runtime')

import {Platform} from '@/platform'
import {Site} from '@/site'

document.addEventListener('DOMContentLoaded', () => {
  /**
   * создаем управляющие структуры
   * и сохраняем ссылки на них в Window.
   *
   * можно посмотреть в консоли
   *
   * ```js
   * console.log(window.PLATFORM)
   * console.log(window.SITE)
   * ```
   */
  window['PLATFORM'] = new Platform()
  window['SITE'] = new Site()
})
