import { TweenLite, gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CSSPlugin } from 'gsap/CSSPlugin'

gsap.registerPlugin(ScrollToPlugin, CSSPlugin)

interface ScrollUpOptions {
  id: string;
  minHeight?: number;
  speed?: number;
  debug?: boolean;
  right?: number;
  bottom?: number
}

/**
 * Кнопка прокрутки страницы наверх.
 *
 * При прокрутке страницы вниз, внизу справа появляется
 * кнопка при клике на которой страница прокручивается вверх
 * к началу.
 *
 * Кнопка отображается при условии что размер окна больше
 * `600px` и пользователь прокрутил страницу на высоту большую
 * размеру видимого окна.
 *
 * @todo проверка отображения `isHidden()`. Сейчас анимация работает
 * все время при прокрутке, это неверно. Отображение и скрытие должно
 * срабатывать только в момент изменения условий, на границах.
 */
export class ScrollUp {
  button: HTMLElement
  id: string
  minHeight: number
  speed: number
  debug: boolean
  bottom: number
  right: number
  hidden: boolean

  constructor(opts: ScrollUpOptions = {
    id: 'scroll-up-button',
    minHeight: 600,
    speed: 0.5,
    bottom: 20,
    right: 30,
    debug: false
  }) {
    this.id = String(opts.id).replace(/\s/g, '')
    this.button =  document.createElement('div')
    this.minHeight = opts.minHeight || 600
    this.speed = opts.speed || .5
    this.bottom = opts.bottom || 20
    this.right = opts.right || 30
    this.debug = opts.debug === true
    this.hidden = true

    this.createButton()
  }

  /**
   * Создание HTML-элемента кнопки
   * и подключение на страницу
   */
  createButton (): void {
    this.button.setAttribute('id', this.id)
    this.button.style.display = 'none'
    this.button.classList.add('button')
    this.button.innerHTML = '<span>&uarr; Наверх</span>'
    this.button.addEventListener('click', this.scrollToTop)

    document.body.append(this.button)
    this.createAnimations()
  }

  /**
   * Инициализация анимации
   */
  createAnimations (): void {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset < this.minHeight) {
        this.debugStyle('ignore')
        this.hideButton()
        return
      }

      if (document.documentElement.clientHeight > this.minHeight
        && window.pageYOffset > document.documentElement.clientHeight) {
          this.debugStyle('show')
          this.showButton()

        } else {
          this.debugStyle('hide')
          this.hideButton()
        }
    }, { passive: true })
  }

  debugStyle (label: string): void {
    if (!this.debug) return

    console.log(
      document.documentElement.clientHeight, window.pageYOffset, this.minHeight,
      'show:flag',
      window.pageYOffset > document.documentElement.clientHeight
      && document.documentElement.clientHeight > this.minHeight,
      'ignore:flag',
      window.pageYOffset < this.minHeight
    )
    console.log(label, window.getComputedStyle(this.button).display, this.isHidden())
  }

  /**
   * показывает кнопку
   */
  showButton(): void {
    // if (!this.hidden) return
    TweenLite.to(this.button, .5, {
      opacity: 1,
      right: this.right,
      bottom: this.bottom,
      position: 'fixed',
      display: 'flex',
      boxShadow: '0 10px 20px rgba(0,0,0,.2)',
      onComplete: () => {
        this.hidden = false
      }
    })
  }

  /**
   * скрывает кнопку
   */
  hideButton(): void {
    // if (this.hidden) return
    TweenLite.to(this.button, .5, {
      opacity: 0,
      bottom: -100,
      right: this.right,
      display: 'none',
      boxShadow: 'none',
      onComplete: () => {
        this.hidden = true
      }
    })
  }

  scrollToTop(): void {
    TweenLite.to(window, 1, {scrollTo: 0})
  }

  /**
   * проверяет видимость элемента на странице
   */
  isHidden(): boolean {
    return window.getComputedStyle(this.button).display === 'none'
  }
}

export function start(id: string): ScrollUp {
  return new ScrollUp({id: id})
}
