<template>
  <div class="platform-callback-form section">
    <validation-observer ref="observer" v-slot="{invalid, handleSubmit}">
      <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title">
          Обратный звонок
        </p>
      </div>

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon v-model="status.error">
          <p><strong>Ошибка! Не удалось отправить сообщение.</strong></p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>

          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{item}}</strong>
            </span>
          </template>
        </b-notification>

        <b-notification type="is-success" @close="onCloseNotification" has-icon v-model="status.success">
          <p><strong>Спасибо! Ваше сообщение отправлено.</strong></p>
          <p>Постараемся рассмотреть ваше обращение как можно быстрее.</p>
        </b-notification>
      </div>

      <div class="form-wrapper" v-if="isNotSuccess" ref="wrapper">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">
          <input type="hidden" name="form_text_36" value="да">

          <div class="columns">
            <div class="column">
              <validation-provider v-slot="v" rules="required">
                <b-field :type="{'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_text_33" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия"></b-input>
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <validation-provider v-slot="v" rules="required">
                <b-field :type="{'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_text_34" :disabled="isLock" v-model="formData.phone" placeholder="Телефон"></b-input>
                </b-field>
              </validation-provider>
            </div>

            <div class="column is-narrow">
              <b-field>
                <button class="button is-primary" type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }">отправить</button>
              </b-field>
            </div>
          </div>

          <p>
            Заполните и отправьте свои контактные данные, <strong>мы Вам перезвоним</strong>.
          </p>
          <p class="help">
            Отправляя форму вы соглашаетесь на обработку ваших персональных данных
            в соответствие с <a href="/agreement/" target="_blank">пользовательским соглашением сайта</a>. Передаваемая контактная информация используется исключительно для связи с Вами.
          </p>

        </form>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  mixins: [FormCommonMixin],
  data () {
    return {
      formId: 3
    }
  }
})
</script>
<style lang="sass" scoped>
@import "../../../sass/_helpers"
.platform-callback-form.section
  padding: 1.4rem
.platform-callback-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem
</style>
