import {initPhotoSwipeLinks} from '../pswp'

/**
 * Для каждой инициализации всегда создается
 * отдельный файл
 *
 * @param selector {string} HTML селектор
 */
function init(selector) {
  initPhotoSwipeLinks(selector)
}

export default {
  init
}
