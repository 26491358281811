import {Swiper, Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions} from 'swiper'

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

/**
 * Обертка для инициализации Swiper.
 * Позволяет сократить количество кода и не импортировать
 * библиотеки в каждый файл инициализации.
 *
 * ```javascript
 * import {SwiperMounter} from './swiper-mounter'
 *
 * const mySwiper = new SwiperMounter().start('.my-swiper', {
 *   slidesPerView: 3
 * })
 * ```
 *
 */
export class SwiperMounter {
  opts: SwiperOptions = {
    watchSlidesProgress: true,
    slidesPerView: 1,
    breakpoints: {},
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    },
    scrollbar: {
      el: '.swiper-scrollbar'
    }
  }

  start(selector: string, opts: SwiperOptions = {}): Swiper|undefined {
    if (!document.querySelector(selector)) return
    const swiperOpts = {...this.opts, ...opts}
    return new Swiper(selector, swiperOpts)
  }
}
