import GalleryExample from './galleries/gallery-example'
import GalleryExampleIndex from './galleries/gallery-example-index'
//import GallerySwiperExample from './galleries/gallery-swiper-example'
import GalleryLinks from './galleries/gallery-links'

/**
 * при использовании runtime-логики
 * шаблон .pswp следует подключить
 * в footer.php шаблона сайта
 *
 * Каждая отдельная инициализация галереи
 * размещается в отдельном файле
 */

export function start() {
  /**
   * @todo удалить если не используется
   */
  GalleryExample.init('.gallery-example')
  GalleryExampleIndex.init('.gallery-example-index')
//  GallerySwiperExample.init('.gallery-swiper-example')

/**
   * Пример поиска элементов в контейнере,
   * без ограничения уровня вложенности.
   * (упрощенный вариант)
   *
   * следует внимательно проверять селекторы
   * и без необходимости не вызывать функцию.
   *
   * лучше всего использовать уникальное сочетание.
   * ссылки конечно можно собирать напрямую a.image,
   * но тогда можно сломать логику и залезть в другие
   * галереи, лучшим решением будет составной селектор
   *
   * ```
   * .unique-container-class > a.unique-item-class
   * ```
   *
   * И конечно перед вызовом, хорошо бы проверить,
   * есть вообще элементы или нет.
   *
   * @todo проверка должна быть в модуле
   *
   *
   * в данном варианте все картинки будут
   * отображаться в одной общей галерее.
   *
   */
  // if (document.querySelectorAll('.js-pswp-image-tiles a.image').length > 0) {
  //   GalleryLinks.init('.js-pswp-image-tiles a.image')
  // }

  /**
   * Вариант для нескольких отдельных галерей
   * на одной странице.
   *
   * создать уникальную галерею для каждой секции,
   * добавим уникальный класс к каждой секции, чтобы
   * разделить показ картинок в раздельных галереях,
   * не в общей
   */
  // // обход всех секций на странице
  // document.querySelectorAll('.js-pswp-images').forEach((el,idx) => {
  //   const cls = `js-pswp-images-${idx}`
  //   el.classList.add(cls)
  //   // поиск и инициализация элементов
  //   // внутри секции
  //   GalleryLinks.init(`.${cls} a.image`)
  // })

  /**
   * чтобы избежать ошибок
   * можно проверять наличие селекторов
   * на странице до запуска библиотеки
   */
  if (document.querySelectorAll('a[rel=gallery]').length > 0) {
    GalleryLinks.init('a[rel=gallery]')
  }
}
